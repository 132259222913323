const cookieTableStructure = {
    caption: 'Related Cookies',
    headers: {
        name: 'Name',
        description: 'Purpose',
        duration: 'Duration',
    },
};

const en = {
    consentModal: {
        label: 'Information regarding your privacy on this website',
        title: 'Your privacy',
        description: `
            <p>Your privacy is important to us, therefore we don't collect any personal data without your consent. You can consent or reject the usage of external services for embedded media and visitors statistics here, or choose custom settings.</p>
            {{revisionMessage}}`,
        acceptAllBtn: 'Accept all',
        acceptNecessaryBtn: 'Only required',
        showPreferencesBtn: 'Custom settings',
        // closeIconLabel: 'Reject all and close',
        revisionMessage:
            '<p>We have made changes to the available options. Please review your settings.</p>',
        footer: `
            <a href="/privacy-policy">Privacy policy</a>
            <a href="/legal">Legal notes</a>
        `,
    },
    preferencesModal: {
        title: 'Privacy settings',
        acceptAllBtn: 'Accept all',
        acceptNecessaryBtn: 'Only required',
        savePreferencesBtn: 'Save custom settings',
        closeIconLabel: 'Close preferences',
        // serviceCounterLabel: 'Service|Services',
        sections: [
            {
                description:
                    'You can configure which cookies and external services we may use. Personal data may be transferred to external services with your consent. Learn more in our <a href="/privacy-policy" class="cc__link">privacy policy</a>.',
            },
            {
                title: 'Always required',
                description:
                    'The cookies listed here are always required to save your privacy settings and prevent forged form submissions.',
                linkedCategory: 'necessary',
                cookieTable: {
                    ...cookieTableStructure,
                    body: [
                        {
                            name: 'privacy_preferences',
                            description: 'Saves your privacy preferences',
                            duration: '365 days',
                        },
                        {
                            name: 'CRAFT_CSRF_TOKEN',
                            description: 'Prevents forged form submissions',
                            duration: 'Session',
                        },
                    ],
                },
            },
            {
                title: 'Statistics',
                description:
                    'We use Matomo to collect visitor statistics. All data is pseudonymized and not transferred to any third parties',
                linkedCategory: 'analytics',
            },
            {
                title: 'External media',
                description:
                    'This category activates embedded media from external services. The listed services may collect personal data when active.',
                linkedCategory: 'media',
                cookieTable: {
                    ...cookieTableStructure,
                    body: [
                        {
                            name: 'im_youtube',
                            description: 'Stores consent for embedded YouTube-Videos',
                            duration: '365 days',
                        },
                        {
                            name: 'im_vimeo',
                            description: 'Stores consent for embedded Vimeo-Videos',
                            duration: '365 days',
                        },
                        {
                            name: '__cf_bm',
                            description: 'Used to differentiate real users from bots.',
                            duration: '30 minutes',
                        },
                    ],
                },
            },
        ],
    },
};

export { en };
